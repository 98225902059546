.LandingHeadline {
    /*padding-top: 67px;*/
    background: white;
}

.LandingHeadline picture{
  text-align: right
}

.LandingHeadline img{
  object-fit: contain!important;
  width: 100%;
  max-height: 90vh;
}


@media (min-width: 992px) {
  .LandingHeadline {
    /*padding-top: 67px;*/
    background: var(--secondary-color);
  }

  .LandingHeadline img{
    object-fit: cover!important;
    width: 75%;
  }
  
  .LandingHeadline .container-fluid.position-relative {
    text-align: right;
  }
  
  .LandingHeadline .mask {
     min-width: 25%;
     width: 25%;
      background-color: rgba(0, 0, 0, 0.0)!important;
  }
}
