.Captcha {
  display:flex;
  font: normal normal normal 16px/20px Open Sans,Arial,Helvetica,sans-serif;
  margin-bottom:2%;
}
.Captcha-input {margin-top:1%;}
/*important to display the checkbox correctly*/
.dx-checkbox-checked .dx-checkbox-icon {
  font: 16px/16px DXIcons;
  color: #337ab7;
  text-align: center;
}
.dx-checkbox-indeterminate .dx-checkbox-icon::before {background-color: white !important;}