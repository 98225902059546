.header-text {
    color: var(--secondary-color);
    margin: 0;
    padding: 10px 20px 0px 20px;
    background-color: white;
    border-radius: 20px 20px 0% 0%;
}

.Header .mask {
     min-width: 100%;
     width: 100%;
     background: linear-gradient(
            to bottom,
            hsla(0, 0%, 0%, 0) 80%,
            hsla(0, 0%, 0%, 0.2)
          );
  }

.Header img {
  max-height: 500px;
  aspect-ratio: 16/9;
  object-fit: cover;
}