.navbar-toggler {
    color: transparent;
    border-color: transparent;
}

.SideBar .nav-item {
    padding: 1em;
}

.SideBar .nav-item i {
    width: 1em;
}