@font-face {
  font-family: "Allura";
  src: url("./fonts/Allura-Regular.ttf")
}

@font-face {
  font-family: "Lato Bold";
  src: url('./fonts/lato/Lato-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url('./fonts/lato/Lato-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

:root {
  --primary-color: #373A36;
  --secondary-color: #c3a476;
  --header-color: #c3a476;
  --header-bg-color: white;
  --header-bg-color: white;
  --footer-color: #E6E2DD;
  --footer-bg-primary-color: #373A36;
  --footer-color: #E6E2DD;
  --footer-bg-primary-color: #373A36;
  --footer-bg-secondary-color: #373A36;
}

body {
  color: var(--primary-color);
  text-align: left;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

.h1, h1,
.h2, h2,
.h3, h3,
.h4, h4{
  font-weight:normal;
  font-style:normal;
}

a {
  text-decoration: none;
  color: var(--secondary-color);
}

a:hover {
  font-weight: bold;
  color: var(--secondary-color);
}

.bg-section-1 {
  background-color: #F5F5F5;
}

.bg-section-2 {
  background-image:
          linear-gradient(to bottom, rgb(55, 58, 54, 0.5), rgb(55, 58, 54, 0.5)),
          url("images/fr2.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}

.btn-color {
  background-color: var(--secondary-color);
  color: white;
}

.bg-color2 {
  background-image:
          linear-gradient(to right, rgb(195, 164, 118), rgb(195, 164, 118, 0.5));
  background-color: rgb(195, 164, 118, 0.5);
  color: white;
}

.color1 {
  color: var(--primary-color);
}

.color2 {
  color: var(--secondary-color);
}

.color3 {
  color:gray;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}

.special {
  font-family: 'Allura', Arial, sans-serif;
}

.Location {
  height: 40vh;
  padding: 0;
  margin:0;
}

.text-small {
    font-size: 0.8em;
}

.mobilcard {
    display: flex;
    gap: 1em;
    align-items: center;
}