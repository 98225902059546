.react-multi-carousel-dot button {
    background: white;
}

.react-multi-carousel-dot--active button {
    background: var(--secondary-color);
}

.react-multiple-carousel__arrow {
    background: transparent;
}